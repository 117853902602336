import ListGroup from "react-bootstrap/ListGroup";
import { useContext } from "react";
import { BoardContext } from "../../Providers/BoardContext";

const TeamSelector = () => {
  const { state, updateState } = useContext(BoardContext);
  const setAttacker = () => {
    updateState({ ...state, activeTeam: "attacker" });
  };

  const setDefender = () => {
    updateState({ ...state, activeTeam: "defender" });
  };
  return (
    <>
      <span>Active Team</span>
      <ListGroup horizontal>
        <ListGroup.Item
          action
          active={state.activeTeam === "attacker"}
          onClick={setAttacker}
        >
          Attacker
        </ListGroup.Item>
        <ListGroup.Item
          action
          active={state.activeTeam === "defender"}
          onClick={setDefender}
        >
          Defender
        </ListGroup.Item>
      </ListGroup>
    </>
  );
};

export default TeamSelector;
