import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { FirebaseContext } from "./FirebaseContext";

export interface LayoutManagerState {
  fullScreen: boolean;
  orientation: "auto" | "portrait" | "landscape";
  mainContainerMargin: number;
  topbarHeight: number;
}

type LayoutContextType = {
  layoutState: LayoutManagerState;
  updateLayoutState: (newState: LayoutManagerState) => void;
};

const defaultState: LayoutManagerState = {
  fullScreen: false,
  orientation: "auto",
  mainContainerMargin: 20,
  topbarHeight: 56,
};

export const LayoutContext = React.createContext<LayoutContextType>({
  layoutState: defaultState,
  updateLayoutState: () => {},
});

type Props = {
  children: React.ReactNode;
};

const LayoutProvider = ({ children }: Props) => {
  const [layoutState, setState] = useState<LayoutManagerState>(defaultState);
  const firebaseContext = useContext(FirebaseContext);
  const location = useLocation();

  const { analytics } = firebaseContext || {};

  useEffect(() => {
    // Log page view to Firebase Analytics
    if (!analytics) return;
    logEvent(analytics, "page_view", {
      page_location: window.location.href,
      page_path: location.pathname,
      page_title: "BbTb - " + location.pathname,
    });
  }, [location.pathname, analytics]);

  const updateLayoutState = (newState: LayoutManagerState) => {
    setState(newState);
  };

  return (
    <LayoutContext.Provider value={{ layoutState, updateLayoutState }}>
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
