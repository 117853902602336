import React, { useContext } from "react";

export interface AlertMessage {
  id?: number;
  severity: "success" | "info" | "warning" | "error";
  header?: string;
  link?: string;
  linkText?: string;
  error?: string;
}

export interface Message extends AlertMessage {
  content: React.ReactNode;
}

export interface AlertContextType {
  messages: Message[];
  addMessage: (message: Message) => void;
  removeMessage: (id: number) => void;
  clearMessages: () => void;
}

export const useAlertContext = () => {
  const alertContext = useContext(AlertContext);

  const { messages, addMessage, removeMessage, clearMessages } =
    alertContext || {
      messages: [],
      addMessage: () => {},
      removeMessage: () => {},
      clearMessages: () => {},
    };
  return {
    messages,
    addMessage,
    removeMessage,
    clearMessages,
  };
};

export const AlertContext: React.Context<AlertContextType | null> =
  React.createContext<AlertContextType | null>(null);
