import { Database, onValue, ref, set, Unsubscribe } from "firebase/database";
import { User } from "firebase/auth";

export type UserProfile = {
  username: string;
  email: string;
};

export type UserSettings = {
  attacker: {
    player: {
      fillStyle: string;
      strokeStyle: string;
      shadowColor: string;
      shadowBlur: number;
      shadowOffsetX: number;
      shadowOffsetY: number;
    };
    tackleZone: string;
    tackleZoneDark: string;
  };
  defender: {
    player: {
      fillStyle: string;
      strokeStyle: string;
      shadowColor: string;
      shadowBlur: number;
      shadowOffsetX: number;
      shadowOffsetY: number;
    };
    tackleZone: string;
    tackleZoneDark: string;
  };
  both: {
    tackleZone: string;
  };
};

export function createUser(
  db: Database,
  user: User,
  username?: string
): [UserProfile, UserSettings] {
  const profile: UserProfile = {
    username: username || user.displayName || user.email || "",
    email: user.email || "",
  };
  const settings: UserSettings = {
    attacker: {
      player: {
        fillStyle: "rgb(250,184,2)",
        strokeStyle: "rgba(0,0,0,1)",
        shadowColor: "rgb(0,0,0)",
        shadowBlur: 5,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
      },
      tackleZone: "rgba(250,184,2,0.2)",
      tackleZoneDark: "rgba(250,184,2,0.3)",
    },
    defender: {
      player: {
        fillStyle: "rgb(1,89,194)",
        strokeStyle: "rgba(0,0,0,1)",
        shadowColor: "rgb(0,0,0)",
        shadowBlur: 5,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
      },
      tackleZone: "rgba(1,89,194,0.15)",
      tackleZoneDark: "rgba(1,89,194,0.4)",
    },
    both: {
      tackleZone: "rgba(255,255,255,0.1)",
    },
  };
  set(ref(db, "users/" + user.uid + "/profile"), profile);
  set(ref(db, "users/" + user.uid + "/settings"), settings);
  return [profile, settings];
}

export function getUser(
  db: Database,
  user: User,
  updateUserProfile: (userProfile: UserProfile) => unknown,
  updateUserSettings: (userSettings: UserSettings) => unknown
): Unsubscribe {
  return onValue(
    ref(db, "/users/" + user.uid),
    (snapshot) => {
      let profile: UserProfile;
      let settings: UserSettings;
      if (!snapshot.exists()) {
        [profile, settings] = createUser(db, user);
      } else {
        profile = snapshot.val().profile;
        settings = snapshot.val().settings;
      }
      updateUserProfile(profile);
      updateUserSettings(settings);
    },
    {
      onlyOnce: true,
    }
  );
}

export function saveUserSettings(
  db: Database,
  user: User,
  settings: UserSettings
): Promise<void> {
  const userRef = ref(db, "users/" + user.uid + "/settings");
  return set(userRef, settings);
}
