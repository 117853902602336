import React, { useContext, useEffect, useState } from "react";
import TopBar from "../Components/TopBar";
import Footer from "../Components/Footer";
import { LayoutContext } from "../Providers/LayoutProvider";
import { Container } from "react-bootstrap";
import Alert from "./Alert";
import { AlertMessage, useAlertContext } from "../Providers/AlertContext";

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  const { layoutState, updateLayoutState } = useContext(LayoutContext);
  const [alerts, setAlerts] = useState<JSX.Element[]>([]);
  const { messages } = useAlertContext();

  useEffect(() => {
    console.debug(
      "Layout: useEffect:",
      layoutState.fullScreen,
      layoutState.orientation
    );
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === "f") {
        updateLayoutState({
          ...layoutState,
          fullScreen: !layoutState.fullScreen,
        });
      }
    }

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  useEffect(() => {
    const alerts = messages.map((message, index) => (
      <Alert message={message as AlertMessage} key={index}>
        {message.content}
      </Alert>
    ));
    setAlerts(alerts);
  }, [messages]);

  return (
    <>
      <div hidden={layoutState.fullScreen} style={{ marginBottom: "10px" }}>
        <TopBar />
      </div>
      <Container>
        <div className="alert-container">{alerts}</div>
      </Container>
      {children}
      <div hidden={layoutState.fullScreen} style={{ marginTop: "10px" }}>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
