import React from "react";
import { Auth, User } from "firebase/auth";
import { Analytics } from "firebase/analytics";
import { Database } from "firebase/database";
import { UserProfile, UserSettings } from "../Database/Users";

export interface FirebaseContextType {
  auth: Auth;
  currentUser: User | null;
  analytics: Analytics;
  db: Database;
  userProfile: UserProfile | null;
  setUserProfile: (userProfile: UserProfile) => void;
  userSettings: UserSettings | null;
  setUserSettings: (userSettings: UserSettings) => void;
}

export const FirebaseContext: React.Context<FirebaseContextType | null> =
  React.createContext<FirebaseContextType | null>(null);
