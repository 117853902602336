import React, { useContext, useEffect, useState } from "react";
import { FirebaseApp } from "firebase/app";
import { getAuth, User } from "firebase/auth";
import { FirebaseContext } from "./FirebaseContext";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getUser, UserProfile, UserSettings } from "../Database/Users";
import { Spinner } from "react-bootstrap";

interface FirebaseProviderProps {
  children: React.ReactNode;
  app: FirebaseApp;
}

export const useFirebaseContext = () => {
  const firebaseContext = useContext(FirebaseContext);
  if (!firebaseContext) {
    throw new Error(
      "FirebaseContext is not defined, please add FirebaseProvider to your app."
    );
  }
  const {
    auth,
    currentUser,
    analytics,
    db,
    userProfile,
    setUserProfile,
    userSettings,
    setUserSettings,
  } = firebaseContext || {};
  return {
    auth,
    currentUser,
    analytics,
    db,
    userProfile,
    setUserProfile,
    userSettings,
    setUserSettings,
  };
};

export const FirebaseProvider = (props: FirebaseProviderProps) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [userSettings, setUserSettings] = useState<UserSettings | null>(null);
  const auth = getAuth(props.app);
  const analytics = getAnalytics(props.app);
  const db = getDatabase(props.app);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (auth.currentUser !== currentUser) {
      setCurrentUser(auth.currentUser);
    }

    if (auth.currentUser) {
      getUser(db, auth.currentUser, setUserProfile, setUserSettings);
      setIsLoading(false);
    }

    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);

      if (user) {
        getUser(db, user, setUserProfile, setUserSettings);
      }

      setIsLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, [auth, db, currentUser]);

  if (isLoading) {
    return (
      <div>
        <Spinner animation="grow" />
      </div>
    );
  }

  return (
    <FirebaseContext.Provider
      value={{
        auth,
        currentUser,
        analytics,
        db,
        userProfile,
        setUserProfile,
        userSettings,
        setUserSettings,
      }}
    >
      {props.children}
    </FirebaseContext.Provider>
  );
};
