import React, { useContext } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCogs,
  faSignIn,
  faSignOut,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { signOut } from "firebase/auth";
import { useFirebaseContext } from "../Providers/FirebaseProvider";
import { LinkContainer } from "react-router-bootstrap";
import { PitchContext } from "../Providers/PitchContext";

const TopBar = () => {
  const { auth, currentUser, userProfile } = useFirebaseContext();
  const { resetTeam } = useContext(PitchContext);

  const signout = () => {
    signOut(auth)
      .then(() => {
        console.debug("Logout successful");
      })
      .catch((error) => {
        console.error("Logout error", error);
      });
  };

  return (
    <Navbar bg="light" expand="md">
      <Container fluid>
        <LinkContainer to="/">
          <Navbar.Brand>BB TacticalBoard</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              href=""
              onClick={() => {
                resetTeam("defender");
                resetTeam("attacker");
              }}
            >
              Reset
            </Nav.Link>
          </Nav>
          <Nav>
            {(!currentUser && (
              <>
                <LinkContainer to="/login">
                  <Nav.Link>
                    <FontAwesomeIcon icon={faSignIn} /> Login
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/signup">
                  <Nav.Link>
                    <FontAwesomeIcon icon={faUserPlus} /> Sign-up
                  </Nav.Link>
                </LinkContainer>
              </>
            )) || (
              <NavDropdown
                title={<FontAwesomeIcon icon={faUser} />}
                id="navbarScrollingDropdown"
                align="end"
              >
                <NavDropdown.ItemText>
                  Signed as: {userProfile?.email} ({userProfile?.username})
                </NavDropdown.ItemText>
                <NavDropdown.Divider />
                <LinkContainer to="/user/settings">
                  <NavDropdown.Item>
                    <FontAwesomeIcon icon={faCogs} /> Settings
                  </NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#signout" onClick={signout}>
                  <FontAwesomeIcon icon={faSignOut} /> Logout
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default TopBar;
