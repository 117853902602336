import React from "react";
import { Alert as BootstrapAlert } from "react-bootstrap";
import { AlertMessage, useAlertContext } from "../Providers/AlertContext";

type AlertProps = {
  message: AlertMessage;
  children: React.ReactNode;
};

const Alert = ({ message, children }: AlertProps) => {
  const { id, severity, header, link, linkText } = message;
  const { removeMessage } = useAlertContext();

  const handleClose = () => {
    if (id) {
      removeMessage(id);
    }
  };

  let variant = severity as string;
  switch (severity) {
    case "info":
      variant = "primary";
      break;
    case "error":
      variant = "danger";
      break;
  }

  let headerText = header;
  if (!header) {
    switch (severity) {
      case "success":
        headerText = "Success!";
        break;
      case "error":
        headerText = "Error!";
        break;
      case "warning":
        headerText = "Warning!";
        break;
      case "info":
        headerText = "Info!";
        break;
    }
  }

  return (
    <div>
      <BootstrapAlert
        key={id}
        variant={variant}
        onClose={handleClose}
        dismissible
      >
        <BootstrapAlert.Heading>{headerText}</BootstrapAlert.Heading>
        <p>{children}</p>
        {link && (
          <>
            <hr />
            <p>
              See{" "}
              <BootstrapAlert.Link href={link}>
                {linkText ? linkText : "here"}
              </BootstrapAlert.Link>{" "}
              for more information.
            </p>
          </>
        )}
      </BootstrapAlert>
    </div>
  );
};

export default Alert;
