import React, { FormEvent, useEffect, useRef } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useFirebaseContext } from "../Providers/FirebaseProvider";
import { Message, useAlertContext } from "../Providers/AlertContext";

const Login = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { auth, currentUser } = useFirebaseContext();
  const { addMessage } = useAlertContext();

  const login = (e: FormEvent) => {
    e.preventDefault();

    console.debug("login", emailRef.current!.value, passwordRef.current!.value);
    signInWithEmailAndPassword(
      auth,
      emailRef.current!.value,
      passwordRef.current!.value
    )
      .then((_userCredential) => {
        const message: Message = {
          severity: "success",
          content: "Login successful!",
        };
        addMessage(message);
        navigate("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const message: Message = {
          severity: "error",
          content: "Unable to login",
          error: `${errorCode}: ` + errorMessage,
        };
        addMessage(message);
      });
  };

  useEffect(() => {}, [currentUser]);

  return (
    <Container style={{ maxWidth: "500px", margin: "20px" }} fluid>
      <Form onSubmit={login}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control ref={emailRef} type="email" placeholder="Enter email" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            ref={passwordRef}
            type="password"
            placeholder="Password"
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default Login;
