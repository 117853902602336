import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./Root";
import Board from "./@core/Pages/Board";
import Login from "./@core/Pages/Login";
import Signup from "./@core/Pages/Signup";
import ColorSettings from "./@core/Pages/ColorSettings";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./@core/Settings";
import { FirebaseProvider } from "./@core/Providers/FirebaseProvider";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      { index: true, element: <Board /> },
      {
        path: "/user/settings",
        element: <ColorSettings />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
    ],
  },
]);

const App = () => {
  const firebaseApp = initializeApp(firebaseConfig);

  return (
    <FirebaseProvider app={firebaseApp}>
      <RouterProvider router={router} />
    </FirebaseProvider>
  );
};

export default App;
