import React from "react";
import Background from "./Background";
import Players from "./Players";

export type PitchOrientation = "portrait" | "landscape" | "auto";
export type PitchDimension = { cols: number; rows: number };
export type Position = [number, number];

export interface PitchProps {
  rows: number;
  cols: number;
  squareSize: number;
  orientation: PitchOrientation;
  width: number;
  height: number;
}

const Pitch = (props: PitchProps) => {
  return (
    <div style={{ position: "relative" }}>
      <Background {...props} />
      <Players {...props} />
    </div>
  );
};

export default Pitch;
