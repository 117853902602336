import React from "react";
import { Formation, Squares, TeamType } from "../Squares";

export interface PitchContextType {
  squares: Squares;
  addPlayer: (col: number, row: number, activeTeam: TeamType) => void;
  removePlayer: (col: number, row: number, activeTeam: TeamType) => void;
  resetTeam: (team: TeamType) => void;
  applyFormation: (formation: Formation) => void;
}

export const PitchContext: React.Context<PitchContextType> =
  React.createContext<PitchContextType>({
    squares: [[]],
    addPlayer: (_col: number, _row: number, _activeTeam: TeamType) => {},
    removePlayer: (_col: number, _row: number, _activeTeam: TeamType) => {},
    resetTeam: (_team: TeamType) => {},
    applyFormation: (_formation: Formation) => {},
  });
