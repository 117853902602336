import Help from "../Components/UI/Help";
import { Col, Container, Row, Stack } from "react-bootstrap";
import Pitch, { PitchProps } from "../Components/Pitch";
import TeamSelector from "../Components/UI/TeamSelector";
import TackleZonesSelector from "../Components/UI/TackleZonesSelector";
import OrientationSelector from "../Components/UI/OrientationSelector";
import React, { useContext, useEffect, useState } from "react";
import { LayoutContext, LayoutManagerState } from "../Providers/LayoutProvider";
import { PITCH_COLS, PITCH_ROWS } from "../Settings";
import FormationSelector from "../Components/UI/FormationSelector";

const calcLayout = (layoutState: LayoutManagerState): PitchProps => {
  console.debug("calcLayout: layoutState", layoutState);
  const layout: PitchProps = {
    rows: PITCH_ROWS,
    cols: PITCH_COLS,
    squareSize: 0,
    orientation: layoutState.orientation,
    width: 0,
    height: 0,
  };

  let height = window.innerHeight - 2 * layoutState.mainContainerMargin;
  if (!layoutState.fullScreen) {
    height -= layoutState.topbarHeight;
  }

  if (layoutState.orientation === "auto") {
    if (window.innerWidth > window.innerHeight) {
      layout.orientation = "landscape";
    } else {
      layout.orientation = "portrait";
    }
  }

  if (layout.orientation === "portrait") {
    layout.rows = PITCH_COLS;
    layout.cols = PITCH_ROWS;
  }

  layout.squareSize = Math.min(
    Math.floor(height / layout.rows),
    Math.floor(window.innerWidth / layout.cols)
  );

  layout.height = layout.rows * layout.squareSize;
  layout.width = layout.cols * layout.squareSize;

  return layout;
};

const Board = () => {
  const { layoutState } = useContext(LayoutContext);

  const [layout, setLayout] = useState<PitchProps>(() => {
    return calcLayout(layoutState);
  });

  useEffect(() => {
    console.debug("Home: useEffect: window.innerHeight", window.innerHeight);

    const recalcLayout = () => {
      console.debug("Home: recalcLayout");

      const newLayout = calcLayout(layoutState);
      setLayout(newLayout);
    };

    recalcLayout();

    window.addEventListener("resize", recalcLayout);
    return () => {
      window.removeEventListener("resize", recalcLayout);
    };
  }, [layoutState]);

  return (
    <>
      <Help />
      <Container fluid>
        <Row>
          <Col>
            <Stack gap={2}>
              <Container
                fluid
                className="d-flex"
                style={{
                  height: `${layout.height}px`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: `${layoutState.mainContainerMargin}px`,
                  marginTop: `${layoutState.mainContainerMargin}px`,
                }}
              >
                <Pitch {...layout} />
              </Container>
              <Container
                fluid
                style={{
                  width: `${layout.width}px`,
                }}
              >
                <Row>
                  <Col
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FormationSelector
                      key="attack-selector"
                      team={"attacker"}
                    />
                  </Col>
                  <Col
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FormationSelector
                      key="defence-selector"
                      team={"defender"}
                    />
                  </Col>
                </Row>
              </Container>
            </Stack>
          </Col>
          <Col style={{ margin: "20px" }}>
            <Stack gap={2} className="mx-auto">
              <TeamSelector />
              <TackleZonesSelector />
              <OrientationSelector />
            </Stack>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Board;
