import React, { useContext, useEffect, useState } from "react";
import { LayoutContext } from "./LayoutProvider";
import {
  BoardContext,
  BoardManagerState,
  defaultBoarManagerState,
} from "./BoardContext";
import { PitchContext } from "./PitchContext";
import { useSquares } from "../Squares";

type Props = {
  children: React.ReactNode;
};

const Board = ({ children }: Props) => {
  const { state, updateState } = useContext(BoardContext);
  const { layoutState, updateLayoutState } = useContext(LayoutContext);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "t") {
        const newActiveTeam =
          state.activeTeam === "attacker" ? "defender" : "attacker";
        console.debug("Change active team to: ", newActiveTeam);
        updateState({ ...state, activeTeam: newActiveTeam });
      } else if (event.key === "z") {
        let newTackleZones: "attacker" | "defender" | "both" | "none";
        switch (state.tackleZones) {
          case "attacker":
            newTackleZones = "defender";
            break;
          case "defender":
            newTackleZones = "both";
            break;
          case "both":
            newTackleZones = "none";
            break;
          case "none":
            newTackleZones = "attacker";
            break;
        }
        console.debug("Change tackle zones to: ", newTackleZones);
        updateState({ ...state, tackleZones: newTackleZones });
      } else if (event.key === "o") {
        let orientation: "auto" | "portrait" | "landscape";
        switch (layoutState.orientation) {
          case "auto":
            orientation = "portrait";
            break;
          case "portrait":
            orientation = "landscape";
            break;
          case "landscape":
            orientation = "auto";
            break;
        }
        updateLayoutState({ ...layoutState, orientation: orientation });
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [state, updateState, layoutState, updateLayoutState]);

  return <div id={"board"}>{children}</div>;
};

const BoardManager = ({ children }: Props) => {
  const [state, setState] = useState<BoardManagerState>(
    defaultBoarManagerState
  );
  const { squares, addPlayer, removePlayer, applyFormation, resetTeam } =
    useSquares();

  const updateState = (newState: BoardManagerState) => {
    setState(newState);
  };

  return (
    <BoardContext.Provider value={{ state, updateState }}>
      <PitchContext.Provider
        value={{ squares, addPlayer, removePlayer, applyFormation, resetTeam }}
      >
        <Board>{children}</Board>
      </PitchContext.Provider>
    </BoardContext.Provider>
  );
};

export default BoardManager;
