import ListGroup from "react-bootstrap/ListGroup";
import { useContext } from "react";
import { BoardContext } from "../../Providers/BoardContext";

const TackleZonesSelector = () => {
  const { state, updateState } = useContext(BoardContext);

  const setBoth = () => {
    updateState({ ...state, tackleZones: "both" });
  };

  const setAttacker = () => {
    updateState({ ...state, tackleZones: "attacker" });
  };

  const setDefender = () => {
    updateState({ ...state, tackleZones: "defender" });
  };

  const setNone = () => {
    updateState({ ...state, tackleZones: "none" });
  };

  return (
    <>
      <span>Displayed Tackle Zones</span>
      <ListGroup horizontal>
        <ListGroup.Item
          action
          active={state.tackleZones === "both"}
          onClick={setBoth}
        >
          Both
        </ListGroup.Item>
        <ListGroup.Item
          action
          active={state.tackleZones === "attacker"}
          onClick={setAttacker}
        >
          Attacker
        </ListGroup.Item>
        <ListGroup.Item
          action
          active={state.tackleZones === "defender"}
          onClick={setDefender}
        >
          Defender
        </ListGroup.Item>
        <ListGroup.Item
          action
          active={state.tackleZones === "none"}
          onClick={setNone}
        >
          None
        </ListGroup.Item>
      </ListGroup>
    </>
  );
};

export default TackleZonesSelector;
