import React from "react";

const Footer = () => {
  const version = process.env.REACT_APP_VERSION?.toString() || "0.0.0";
  const commit =
    process.env.REACT_APP_RENDER_GIT_COMMIT?.toString() || "0000000";

  return (
    <footer color="light" className="text-center text-lg-left">
      <div
        className="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        &copy; {new Date().getFullYear()}
        <a className="text-dark" href="https://bbtb.app/">
          &nbsp;bbtb.app
        </a>
        &nbsp;- v{version}-@{commit}
      </div>
    </footer>
  );
};

export default Footer;
