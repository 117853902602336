import React, { useContext, useEffect, useRef, useState } from "react";
import { PitchProps } from "./Pitch";
import { BoardContext } from "../Providers/BoardContext";
import { PITCH_COLORS, PITCH_COLS, PITCH_ROWS } from "../Settings";
import { PitchContext } from "../Providers/PitchContext";
import { Square, squaresGetPlayerNumber } from "../Squares";
import { useFirebaseContext } from "../Providers/FirebaseProvider";

export type TeamType = "attacker" | "defender";

const Players = (props: PitchProps) => {
  const { state } = useContext(BoardContext);
  const { squares, addPlayer, removePlayer } = useContext(PitchContext);
  const { userSettings } = useFirebaseContext();

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [currentCursorValue, setCurrentCursorValue] = useState("copy");

  const getPosition = (
    event: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
    canvas: HTMLCanvasElement
  ): { row: number; col: number } | undefined => {
    const squareSize = props.squareSize;
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    let col = Math.floor(x / squareSize);
    let row = Math.floor(y / squareSize);
    if (props.orientation === "portrait") {
      col = PITCH_COLS - 1 - Math.floor(y / squareSize);
      row = Math.floor(x / squareSize);
    }

    if (col < 0 || col >= PITCH_COLS || row < 0 || row >= PITCH_ROWS) {
      return;
    }

    return { row: row, col: col };
  };

  const handleCanvasMouseMove = (
    event: React.MouseEvent<HTMLCanvasElement, MouseEvent>
  ) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const position = getPosition(event, canvas);
    if (!position) {
      return;
    }
    const row = position.row;
    const col = position.col;
    const square = { ...squares[col][row] };
    if (square.player) {
      if (square.player.team === state.activeTeam) {
        setCurrentCursorValue("pointer");
      } else {
        setCurrentCursorValue("not-allowed");
      }
    } else if (squaresGetPlayerNumber(squares, state.activeTeam) >= 11) {
      setCurrentCursorValue("not-allowed");
    } else {
      setCurrentCursorValue("copy");
    }
  };

  const handleCanvasClick = (
    event: React.MouseEvent<HTMLCanvasElement, MouseEvent>
  ) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const position = getPosition(event, canvas);
    if (!position) {
      return;
    }
    const row = position.row;
    const col = position.col;

    const playersCount = squaresGetPlayerNumber(squares, state.activeTeam);

    if (squares[col][row].player) {
      if (squares[col][row].player?.team === state.activeTeam) {
        removePlayer(col, row, state.activeTeam);
      }
    } else if (playersCount < 11) {
      addPlayer(col, row, state.activeTeam);
    }
  };

  useEffect(() => {
    const settings = userSettings ? userSettings : PITCH_COLORS;

    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const squareSize = props.squareSize;

    let square: Square;
    for (let col = 0; col < PITCH_COLS; col++) {
      for (let row = 0; row < PITCH_ROWS; row++) {
        square = squares[col][row];

        let x = col * squareSize;
        let y = row * squareSize;
        if (props.orientation === "portrait") {
          x = row * squareSize;
          y = (PITCH_COLS - 1 - col) * squareSize;
        }

        ctx.clearRect(x, y, squareSize, squareSize);
        ctx.save();
        ctx.beginPath();
        if (square.player) {
          ctx.fillStyle = settings[square.player.team].player.fillStyle;
          ctx.shadowColor = PITCH_COLORS[square.player.team].player.shadowColor;
          ctx.strokeStyle = PITCH_COLORS[square.player.team].player.strokeStyle;
          ctx.shadowBlur = PITCH_COLORS[square.player.team].player.shadowBlur;
          ctx.shadowOffsetX =
            PITCH_COLORS[square.player.team].player.shadowOffsetX;
          ctx.shadowOffsetY =
            PITCH_COLORS[square.player.team].player.shadowOffsetY;
          ctx.arc(
            x + squareSize / 2,
            y + squareSize / 2,
            squareSize / 2 - 5,
            0,
            2 * Math.PI
          );
          ctx.fill();
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillStyle = "black";
          ctx.fillText("L", x + squareSize / 2, y + squareSize / 2, squareSize);
        } else if (
          square.tackleZone.attacker.count >= 1 &&
          square.tackleZone.defender.count >= 1 &&
          state.tackleZones === "both"
        ) {
          ctx.fillStyle = PITCH_COLORS["both"].tackleZone;
          ctx.fillRect(x, y, squareSize, squareSize);
        } else if (
          square.tackleZone.attacker.count === 1 &&
          (state.tackleZones === "attacker" || state.tackleZones === "both")
        ) {
          ctx.fillStyle = settings["attacker"].tackleZone;
          ctx.fillRect(x, y, squareSize, squareSize);
        } else if (
          square.tackleZone.defender.count === 1 &&
          (state.tackleZones === "defender" || state.tackleZones === "both")
        ) {
          ctx.fillStyle = settings["defender"].tackleZone;
          ctx.fillRect(x, y, squareSize, squareSize);
        } else if (
          square.tackleZone.attacker.count > 1 &&
          (state.tackleZones === "attacker" || state.tackleZones === "both")
        ) {
          ctx.fillStyle = settings["attacker"].tackleZoneDark;
          ctx.fillRect(x, y, squareSize, squareSize);
        } else if (
          square.tackleZone.defender.count > 1 &&
          (state.tackleZones === "defender" || state.tackleZones === "both")
        ) {
          ctx.fillStyle = settings["defender"].tackleZoneDark;
          ctx.fillRect(x, y, squareSize, squareSize);
        }
        ctx.restore();
      }
    }
  }, [
    squares,
    props.squareSize,
    state.tackleZones,
    props.orientation,
    userSettings,
  ]);

  return (
    <canvas
      ref={canvasRef}
      width={props.squareSize * props.cols}
      height={props.squareSize * props.rows}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        cursor: currentCursorValue,
      }}
      onClick={handleCanvasClick}
      onMouseMove={handleCanvasMouseMove}
    />
  );
};

export default Players;
