import React, { useEffect, useState } from "react";
import { Form, Popover, Row, Stack } from "react-bootstrap";
import { useFirebaseContext } from "../Providers/FirebaseProvider";
import { get } from "lodash";
import { AlphaPicker, ChromePicker, ColorResult, RGBColor } from "react-color";

export function rgbaColorToString(color: RGBColor) {
  return `rgb(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
}

export function rgbaColorFromString(colorStr: string | undefined): RGBColor {
  if (!colorStr) {
    return {
      r: 0,
      g: 0,
      b: 0,
      a: 1,
    };
  }
  const rgba = colorStr
    .replace(/rgba?\(/, "")
    .replace(")", "")
    .split(",")
    .map((s) => parseFloat(s.trim()));
  return {
    r: rgba[0],
    g: rgba[1],
    b: rgba[2],
    a: rgba[3] || 1,
  };
}

type ColoredSquareProps = {
  color: string;
  onClick?: () => void;
};

const ColoredSquare = ({ color, onClick }: ColoredSquareProps) => {
  const squareStyle = {
    width: "20px",
    height: "20px",
    backgroundColor: color,
    cursor: "pointer",
    margin: "2px",
  };

  return <div onClick={onClick} style={squareStyle}></div>;
};

type ColorPickerProps = {
  label: string;
  team?: string;
  setSetting: (path: string, value: any) => void;
};

const ColorPicker = (props: ColorPickerProps) => {
  const { userSettings } = useFirebaseContext();
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
  const [color, setColor] = useState<RGBColor>(
    rgbaColorFromString(get(userSettings, `${props.team}.player.fillStyle`))
  );
  const [tackleZoneAlpha, setTackleZoneAlpha] = useState<RGBColor>(
    rgbaColorFromString(get(userSettings, `${props.team}.tackleZone`))
  );
  const [tackleZoneDarkAlpha, setTackleZoneDarkAlpha] = useState<RGBColor>(
    rgbaColorFromString(get(userSettings, `${props.team}.tackleZoneDark`))
  );

  useEffect(() => {
    setColor(
      rgbaColorFromString(get(userSettings, `${props.team}.player.fillStyle`))
    );
    setTackleZoneAlpha(
      rgbaColorFromString(get(userSettings, `${props.team}.tackleZone`))
    );
    setTackleZoneDarkAlpha(
      rgbaColorFromString(get(userSettings, `${props.team}.tackleZoneDark`))
    );
  }, [userSettings, props.team]);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleChangeComplete = (tcolor: ColorResult) => {
    const newColor = tcolor.rgb;
    setColor(newColor);
    let rgbString = `rgb(${newColor.r},${newColor.g},${newColor.b},${newColor.a})`;
    props.setSetting(`${props.team}.player.fillStyle`, rgbString);

    const newTackleZoneAlpha = { ...newColor };
    newTackleZoneAlpha.a = tackleZoneAlpha.a;
    setTackleZoneAlpha(newTackleZoneAlpha);
    rgbString = `rgb(${newTackleZoneAlpha.r},${newTackleZoneAlpha.g},${newTackleZoneAlpha.b},${newTackleZoneAlpha.a})`;
    props.setSetting(`${props.team}.tackleZone`, rgbString);

    const newTackleZoneDarkAlpha = { ...newColor };
    newTackleZoneDarkAlpha.a = tackleZoneDarkAlpha.a;
    setTackleZoneDarkAlpha(newTackleZoneDarkAlpha);
    rgbString = `rgb(${newTackleZoneDarkAlpha.r},${newTackleZoneDarkAlpha.g},${newTackleZoneDarkAlpha.b},${newTackleZoneDarkAlpha.a})`;
    props.setSetting(`${props.team}.tackleZoneDark`, rgbString);
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalColor">
        <Form.Label column sm={2}>
          {props.label}
        </Form.Label>
        <Stack direction="horizontal" gap={2}>
          <ColoredSquare
            color={rgbaColorToString(color)}
            onClick={handleClick}
          />
          <ColoredSquare
            color={rgbaColorToString(tackleZoneAlpha)}
            onClick={handleClick}
          />
          <ColoredSquare
            color={rgbaColorToString(tackleZoneDarkAlpha)}
            onClick={handleClick}
          />
        </Stack>
      </Form.Group>

      {displayColorPicker ? (
        <Popover>
          <Popover.Header as="h3">Pick a color</Popover.Header>
          <Popover.Body>
            <ChromePicker color={color} onChange={handleChangeComplete} />
            <div style={{ marginTop: "10px" }}>
              <AlphaPicker
                width="100%"
                color={tackleZoneAlpha}
                onChange={(tcolor: ColorResult) => {
                  setTackleZoneAlpha(tcolor.rgb);
                  props.setSetting(
                    `${props.team}.tackleZone`,
                    rgbaColorToString(tcolor.rgb)
                  );
                }}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <AlphaPicker
                width="100%"
                color={tackleZoneDarkAlpha}
                onChange={(tcolor: ColorResult) => {
                  setTackleZoneDarkAlpha(tcolor.rgb);
                  props.setSetting(
                    `${props.team}.tackleZoneDark`,
                    rgbaColorToString(tcolor.rgb)
                  );
                }}
              />
            </div>
          </Popover.Body>
        </Popover>
      ) : null}
    </>
  );
};

export default ColorPicker;
