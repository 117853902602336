import ListGroup from "react-bootstrap/ListGroup";
import { useContext } from "react";
import { LayoutContext } from "../../Providers/LayoutProvider";

const OrientationSelector = () => {
  const { layoutState, updateLayoutState } = useContext(LayoutContext);
  const setLandscape = () => {
    updateLayoutState({ ...layoutState, orientation: "landscape" });
  };

  const setPortrait = () => {
    updateLayoutState({ ...layoutState, orientation: "portrait" });
  };

  const setAuto = () => {
    updateLayoutState({ ...layoutState, orientation: "auto" });
  };

  return (
    <>
      <span>Active Team</span>
      <ListGroup horizontal>
        <ListGroup.Item
          action
          active={layoutState.orientation === "auto"}
          onClick={setAuto}
        >
          Auto
        </ListGroup.Item>
        <ListGroup.Item
          action
          active={layoutState.orientation === "landscape"}
          onClick={setLandscape}
        >
          Landscape
        </ListGroup.Item>
        <ListGroup.Item
          action
          active={layoutState.orientation === "portrait"}
          onClick={setPortrait}
        >
          Portrait
        </ListGroup.Item>
      </ListGroup>
    </>
  );
};

export default OrientationSelector;
