import React from "react";
import { Button, Container, Form, Row } from "react-bootstrap";
import { useFirebaseContext } from "../Providers/FirebaseProvider";
import { set } from "lodash";
import { saveUserSettings, UserSettings } from "../Database/Users";
import { Navigate } from "react-router-dom";
import { User } from "firebase/auth";
import { Message, useAlertContext } from "../Providers/AlertContext";
import ColorPicker from "../Components/ColorPicker";
import { PITCH_COLORS } from "../Settings";

const ColorSettings = () => {
  const { db, currentUser, userSettings, setUserSettings } =
    useFirebaseContext();
  const { addMessage } = useAlertContext();

  const saveSettings = (path: string, value: string) => {
    const settings = { ...userSettings } as UserSettings;
    set(settings, path, value);
    setUserSettings(settings);
  };

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    saveUserSettings(db, currentUser as User, userSettings as UserSettings)
      .then((_r) => {
        const message: Message = {
          severity: "success",
          content: "Settings saved!",
        };
        addMessage(message);
      })
      .catch((error) => {
        const message: Message = {
          severity: "error",
          content: "Error saving settings",
          error: error,
        };
        addMessage(message);
      });
  };

  const handleReset = () => {
    const settings = { ...userSettings } as UserSettings;
    settings.attacker.player.fillStyle = PITCH_COLORS.attacker.player.fillStyle;
    settings.attacker.tackleZone = PITCH_COLORS.attacker.tackleZone;
    settings.attacker.tackleZoneDark = PITCH_COLORS.attacker.tackleZoneDark;
    settings.defender.player.fillStyle = PITCH_COLORS.defender.player.fillStyle;
    settings.defender.tackleZone = PITCH_COLORS.defender.tackleZone;
    settings.defender.tackleZoneDark = PITCH_COLORS.defender.tackleZoneDark;
    setUserSettings(settings);
  };

  if (!currentUser) {
    return <Navigate replace to="/" />;
  } else {
    return (
      <Container fluid className="justify-content-md-center">
        <Row>
          <Form onSubmit={handleOnSubmit}>
            <ColorPicker
              label={"Attacker color"}
              team={"attacker"}
              setSetting={saveSettings}
            />
            <ColorPicker
              label={"Defender color"}
              team={"defender"}
              setSetting={saveSettings}
            />
            <Button variant="primary" type="submit">
              Save
            </Button>
            <Button variant="warning" onClick={handleReset}>
              Reset
            </Button>
          </Form>
        </Row>
      </Container>
    );
  }
};

export default ColorSettings;
