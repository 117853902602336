import Form from "react-bootstrap/Form";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { PitchContext } from "../../Providers/PitchContext";
import { Formation } from "../../Squares";
import Alert from "react-bootstrap/Alert";

type FormationSelectorProps = {
  team: "attacker" | "defender";
};

const TEAM_FORMATIONS = {
  attacker: "attacks",
  defender: "defences",
};

const FormationSelector = ({ team }: FormationSelectorProps) => {
  const [selectedValue, setSelectedValue] = useState("");

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { applyFormation } = useContext(PitchContext);

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/data/" + TEAM_FORMATIONS[team] + ".json")
      .then((res) => res.json())
      .then(
        (result) => {
          setData(result);
          setLoading(false);
        },
        (error) => {
          setError(error);
          setLoading(false);
        }
      );
  });

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.debug("FormationSelector: handleOnSubmit", selectedValue);
    const formation: Formation | undefined = data.find(
      (item: Formation) => item.name === selectedValue
    );
    if (formation) {
      applyFormation(formation);
    }
  };

  return (
    <Form onSubmit={handleOnSubmit}>
      <Row hidden={!error} style={{ margin: "5px" }}>
        <Alert variant={"danger"}>
          An error occured while loading the formations: {error}
        </Alert>
      </Row>
      <Row>
        <Col>
          <Spinner hidden={!loading} animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <Form.Select
            value={selectedValue}
            onChange={(e) => setSelectedValue(e.target.value)}
            aria-label="Default select example"
          >
            <option value="">Choose a formation</option>
            {data.map((item: Formation) => (
              <option key={item.name} value={item.name}>
                {item.name}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col>
          <Button variant="primary" type="submit">
            Apply
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FormationSelector;
