import React from "react";
import LayoutProvider from "./@core/Providers/LayoutProvider";
import Layout from "./@core/Components/Layout";
import BoardManager from "./@core/Providers/BoardManager";
import { Outlet } from "react-router-dom";
import AlertProvider from "./@core/Providers/AlertProvider";

// Root is the entry point for the application.
// It is the only component that is aware of the
// LayoutManager and Layout components.
const Root = () => {
  return (
    <AlertProvider>
      <LayoutProvider>
        <BoardManager>
          <Layout>
            <div id="main">
              <Outlet />
            </div>
          </Layout>
        </BoardManager>
      </LayoutProvider>
    </AlertProvider>
  );
};

export default Root;
