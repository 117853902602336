import React, { useEffect, useRef } from "react";
import { PitchProps } from "./Pitch";

const drawLines = (ctx: CanvasRenderingContext2D, layout: PitchProps) => {
  const { squareSize, rows, cols, orientation, width, height } = layout;

  ctx.save();
  ctx.beginPath();
  ctx.lineWidth = 1;
  ctx.strokeStyle = "rgba(255,255,255,0.5)";

  if (orientation === "portrait") {
    ctx.fillStyle = "rgba(22,93,170,0.3)";
    ctx.fillRect(0, 0, width, squareSize);
    ctx.moveTo(0, squareSize); // top |
    ctx.lineTo(width, squareSize);

    ctx.moveTo(0, (rows / 2) * squareSize); // middle |
    ctx.lineTo(width, (rows / 2) * squareSize);

    ctx.fillStyle = "rgba(250,184,2,0.3)";
    ctx.fillRect(0, (rows - 1) * squareSize, width, squareSize);
    ctx.moveTo(0, (rows - 1) * squareSize); // bottom |
    ctx.lineTo(width, (rows - 1) * squareSize);
  } else {
    ctx.fillStyle = "rgba(250,184,2,0.1)";
    ctx.fillRect(0, 0, squareSize, height);
    ctx.moveTo(squareSize, 0); // left |
    ctx.lineTo(squareSize, height);

    ctx.moveTo((cols / 2) * squareSize, 0); // middle |
    ctx.lineTo((cols / 2) * squareSize, height);

    ctx.fillStyle = "rgba(22,93,170,0.2)";
    ctx.fillRect((cols - 1) * squareSize, 0, squareSize, height);
    ctx.moveTo((cols - 1) * squareSize, 0); // right |
    ctx.lineTo((cols - 1) * squareSize, height);
  }
  ctx.stroke();
  ctx.restore();
};

const drawSideLines = (ctx: CanvasRenderingContext2D, layout: PitchProps) => {
  const { squareSize, rows, cols, orientation, width, height } = layout;

  ctx.save();
  ctx.beginPath();
  ctx.lineWidth = 1;
  ctx.strokeStyle = "rgba(255,255,255,0.5)";
  ctx.setLineDash([5, 10]);

  if (orientation === "portrait") {
    ctx.moveTo(4 * squareSize, 0); // Left -
    ctx.lineTo(4 * squareSize, height);

    ctx.moveTo((cols - 4) * squareSize, 0); // Right -
    ctx.lineTo((cols - 4) * squareSize, height);
  } else {
    ctx.moveTo(0, 4 * squareSize); // Top -
    ctx.lineTo(width, 4 * squareSize);

    ctx.moveTo(0, (rows - 4) * squareSize); // Down -
    ctx.lineTo(width, (rows - 4) * squareSize);
  }
  ctx.stroke();

  ctx.restore();
};

const Background = (props: PitchProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");
    if (!ctx) return;

    const { width, height } = props;

    const squareSize = props.squareSize;

    ctx.fillStyle = "rgba(26,112,26,0.77)";
    ctx.fillRect(0, 0, width, height);

    ctx.lineWidth = 1;
    ctx.strokeStyle = "#000";
    ctx.strokeRect(0, 0, width, height);

    ctx.save();
    ctx.beginPath();
    ctx.lineWidth = 0.5;
    ctx.strokeStyle = "rgba(0,0,0,0.17)";
    const cornerSize = 3;
    for (let row = 0; row < props.rows; row++) {
      for (let col = 0; col < props.cols; col++) {
        const x = col * squareSize;
        const y = row * squareSize;
        ctx.moveTo(x, y + cornerSize);
        ctx.lineTo(x, y); // top left
        ctx.lineTo(x + cornerSize, y);
        ctx.moveTo(x + squareSize - cornerSize, y);
        ctx.lineTo(x + squareSize, y); // top right
        ctx.lineTo(x + squareSize, y + cornerSize);
        ctx.moveTo(x + squareSize, y + squareSize - cornerSize);
        ctx.lineTo(x + squareSize, y + squareSize); // bottom right
        ctx.lineTo(x + squareSize - cornerSize, y + squareSize);
        ctx.moveTo(x + cornerSize, y + squareSize);
        ctx.lineTo(x, y + squareSize); // bottom left
        ctx.lineTo(x, y + squareSize - cornerSize);
      }
    }
    ctx.stroke();
    ctx.restore();

    drawLines(ctx, props);
    drawSideLines(ctx, props);

    // Draw center circle
    const radius = squareSize * 0.1;
    const centerX = width / 2;
    const centerY = height / 2;
    ctx.beginPath();
    ctx.fillStyle = "rgba(255,255,255,0.5)";
    ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
    ctx.fill();
  }, [props]);

  return (
    <canvas
      width={props.width}
      height={props.height}
      style={{ zIndex: 1 }}
      ref={canvasRef}
    />
  );
};

export default Background;
