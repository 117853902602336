import React from "react";
import { TeamType } from "../Components/Players";

export interface BoardManagerState {
  currentBoard: string;
  activeTeam: TeamType;
  tackleZones: "attacker" | "defender" | "both" | "none";
  orientation: "auto" | "portrait" | "landscape";
  setBoard?: (BoardId: string) => Promise<void>;
  resetBoard?: () => Promise<void>;
  setBoardState?: (key: string, value: any) => void;
  getBoardState?: (key: string) => any;
}

type BoardManagerContextType = {
  state: BoardManagerState;
  updateState: (newState: BoardManagerState) => void;
};

export const defaultBoarManagerState: BoardManagerState = {
  currentBoard: "default",
  activeTeam: "attacker",
  tackleZones: "both",
  orientation: "auto",
};

export const BoardContext = React.createContext<BoardManagerContextType>({
  state: defaultBoarManagerState,
  updateState: () => {},
});
