import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";

const Help = () => {
  const [isShowed, setIsShowed] = useState(false);

  const handleClose = () => setIsShowed(false);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === "h") {
        setIsShowed(!isShowed);
      }
    }

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isShowed]);

  return (
    <Offcanvas show={isShowed} onHide={handleClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Help</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        - to add a player, click on an empty square of the pitch <br />
        - to remove a player, click on it <br />
        - to change active team, press t <br />
        - to change displayed tackle zones, press z <br />
        - to display only the pitch, press f <br />
        - to display this help, press h <br />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default Help;
