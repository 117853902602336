import React, { useState } from "react";
import { AlertContext, Message } from "./AlertContext";

interface AlertProviderProps {
  children: React.ReactNode;
}

const AlertProvider = (props: AlertProviderProps) => {
  const [messages, setMessages] = useState<Message[]>([]);

  const addMessage = (message: Message) => {
    message.id = Date.now();
    setMessages([...messages, message]);

    if (message.severity === "error" && message.error) {
      console.error(message.error);
    }
  };

  const removeMessage = (id: number) => {
    setMessages(messages.filter((m) => m.id !== id));
  };

  const clearMessages = () => {
    setMessages([]);
  };

  return (
    <AlertContext.Provider
      value={{
        messages,
        addMessage,
        removeMessage,
        clearMessages,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
