import { useState } from "react";
import { PITCH_COLS, PITCH_ROWS } from "./Settings";

type Coordinate = [number, number];

export interface Player {
  id?: string;
  num?: number;
  team: TeamType;
  position?: string;
}

export interface FormationPlayer extends Player {
  square: Coordinate;
}

export type Formation = {
  name: string;
  team: TeamType;
  players: FormationPlayer[];
};

export type TeamType = "attacker" | "defender";

export interface Square {
  id: string;
  player: Player | null;
  tackleZone: {
    attacker: { count: number };
    defender: { count: number };
  };
}

export type Squares = Square[][];

function squaresUpdateTackleZones(
  squares: Squares,
  col: number,
  row: number,
  team: TeamType,
  count: -1 | 1
): void {
  if (col > 0) {
    if (row > 0) {
      squares[col - 1][row - 1].tackleZone[team].count += count;
    }
    squares[col - 1][row].tackleZone[team].count += count;

    if (row < PITCH_ROWS - 1) {
      squares[col - 1][row + 1].tackleZone[team].count += count;
    }
  }

  if (col < PITCH_COLS - 1) {
    if (row > 0) {
      squares[col + 1][row - 1].tackleZone[team].count += count;
    }
    squares[col + 1][row].tackleZone[team].count += count;
    if (row < PITCH_ROWS - 1) {
      squares[col + 1][row + 1].tackleZone[team].count += count;
    }
  }

  if (row > 0) {
    squares[col][row - 1].tackleZone[team].count += count;
  }

  if (row < PITCH_ROWS - 1) {
    squares[col][row + 1].tackleZone[team].count += count;
  }
}

function squaresAddPlayer(
  squares: Squares,
  col: number,
  row: number,
  player: Player
): void {
  if (squares[col][row].player) {
    return;
  }

  squares[col][row].player = player;
  squaresUpdateTackleZones(squares, col, row, player.team, 1);
}

function squaresRemovePlayer(
  squares: Squares,
  col: number,
  row: number,
  activeTeam: TeamType
): void {
  if (squares[col][row].player === null) {
    return;
  }
  // @ts-ignore
  if (squares[col][row].player.team !== activeTeam) {
    return;
  }
  // @ts-ignore
  const team = squares[col][row].player.team;
  squares[col][row].player = null;
  squaresUpdateTackleZones(squares, col, row, team, -1);
}

function squaresCleanTeam(squares: Squares, team: TeamType): void {
  for (let i = 0; i < PITCH_COLS; i++) {
    for (let j = 0; j < PITCH_ROWS; j++) {
      if (squares[i][j].player?.team === team) {
        squaresRemovePlayer(squares, i, j, team);
      }
    }
  }
}

function squaresApplyFormation(squares: Squares, formation: Formation): void {
  squaresCleanTeam(squares, formation.team);

  formation.players.forEach((player) => {
    player.team = formation.team;
    squaresAddPlayer(
      squares,
      player.square[0],
      player.square[1],
      player as Player
    );
  });
}

export function squaresGetPlayerNumber(
  squares: Squares,
  activeTeam: TeamType
): number {
  let count = 0;

  for (let i = 0; i < squares.length; i++) {
    for (let j = 0; j < squares[i].length; j++) {
      if (squares[i][j].player?.team === activeTeam) {
        count++;
      }
    }
  }

  return count;
}

interface UseSquares {
  squares: Squares;
  addPlayer: (col: number, row: number, activeTeam: TeamType) => void;
  removePlayer: (col: number, row: number, activeTeam: TeamType) => void;
  resetTeam: (team: TeamType) => void;
  applyFormation: (formation: Formation) => void;
}

export const useSquares = (): UseSquares => {
  const [squares, setSquares] = useState<Squares>(
    Array(PITCH_COLS)
      .fill(null)
      .map((value, col) => {
        return Array(PITCH_ROWS)
          .fill(null)
          .map((value, row) => {
            return {
              id: `${col}-${row}`,
              player: null,
              tackleZone: { attacker: { count: 0 }, defender: { count: 0 } },
            };
          });
      })
  );

  const addPlayer = (col: number, row: number, activeTeam: TeamType) => {
    let newSquares = [...squares];

    const player: Player = {
      id: "1",
      team: activeTeam,
      position: "Lineman",
    };

    squaresAddPlayer(newSquares, col, row, player);

    setSquares(newSquares);
  };

  const removePlayer = (col: number, row: number, activeTeam: TeamType) => {
    let newSquares = [...squares];

    squaresRemovePlayer(newSquares, col, row, activeTeam);

    setSquares(newSquares);
  };

  const resetTeam = (team: TeamType) => {
    let newSquares = [...squares];
    squaresCleanTeam(newSquares, team);
    setSquares(newSquares);
  };

  const applyFormation = (formation: Formation) => {
    let newSquares = [...squares];
    squaresApplyFormation(newSquares, formation);
    setSquares(newSquares);
  };

  return {
    squares,
    addPlayer,
    removePlayer,
    resetTeam,
    applyFormation,
  };
};
