export const PITCH_ROWS: number = 15;
export const PITCH_COLS: number = 26;

export const PITCH_COLORS = {
  attacker: {
    player: {
      fillStyle: "rgb(250,184,2)",
      strokeStyle: "rgba(0,0,0,1)",
      shadowColor: "rgb(0,0,0)",
      shadowBlur: 5,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
    },
    tackleZone: "rgba(250,184,2,0.2)",
    tackleZoneDark: "rgba(250,184,2,0.3)",
  },
  defender: {
    player: {
      fillStyle: "rgb(1,89,194)",
      strokeStyle: "rgba(0,0,0,1)",
      shadowColor: "rgb(0,0,0)",
      shadowBlur: 5,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
    },
    tackleZone: "rgba(1,89,194,0.15)",
    tackleZoneDark: "rgba(1,89,194,0.4)",
  },
  both: {
    tackleZone: "rgba(255,255,255,0.1)",
  },
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
};
